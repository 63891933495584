const SidebarPostsConts = [
  {
    title: "Expert Tips to Publicize Your Book Effectively with a Ghostwriter",
    date: "October 04, 2024",
    url: "/ktn/afroprenuership/expert-tips-to-publicize-your-book",
  },
  {
    title: "Why Your Story Matters: How Ghostwriting Can Bring Your Life to the Page",
    date: "September 27, 2024",
    url: "/ktn/afroprenuership/tell-your-story-the-power-of-ghostwriting",
  },
  {
    title: "How to Help Clients Choose the Best Publishing Path: A Comprehensive Guide for Ghostwriters",
    date: "September 20, 2024",
    url: "/ktn/afroprenuership/how-to-help-clients-choose-the-best-publishing-path",
  },
  {
    title: "Key Lessons to Learn from Famous Ghostwritten Books",
    date: "September 13, 2024",
    url: "/ktn/afroprenuership/key-lessons-from-famous-ghostwritten-books",
  },  {
    title: "Ghostwriting for Authors: Turning Ideas into Bestsellers",
    date: "August 02, 2024",
    url: "/ktn/afroprenuership/ghostwriting-for-authors",
  },



];

export default SidebarPostsConts;
