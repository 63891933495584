import React from "react";
import Navbar from "../Navbar/Navbar";
import bima from "../../Images/homeHero.webp";
import { NavLink } from "react-router-dom";
import "./Hero.css";

import Icon1 from "../../Images/parthners/cclb.png";
import Icon2 from "../../Images/parthners/wealthywomen.webp";
import Icon3 from "../../Images/parthners/largobb.png";
import Icon4 from "../../Images/parthners/rjenb.png";
import Icon5 from "../../Images/parthners/dewtech.svg";
import Icon6 from "../../Images/parthners/kamwebb.png";
import Icon7 from "../../Images/parthners/humanlifeb.png";

const Hero = () => {
  return (
    <div
      className="relative bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${bima})` }}
    >
      <Navbar /> {/* Include your Navbar component */}
      <div className="relative bg-gray-900 bg-opacity-75 py-44 mt-10 md:py-60 md:mt-26">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h1 className="font-extrabold text-white text-3xl md:text-5xl">
              Living life is your role,
              <br /> telling and selling your story is ours
            </h1>
            <p className="mt-10 text-md md:text-xl text-gray-300">
              We weave narratives to compel your audience with ghostwritten and
              marketing content
            </p>
            <div className="mt-10 flex justify-center">
              <a
                href="https://calendly.com/kalukalu/discovery-call"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-[#14143a] hover:bg-indigo-600 text-white py-4 px-3 rounded text-sm font-semibold mr-4 transition duration-300 w-48 text-center"
              >
                Free Discovery Call
              </a>
              <NavLink
                to="/readktn"
                className="inline-block bg-[#14143a] hover:bg-indigo-600 text-white py-4 px-3 rounded text-sm font-semibold ml-4 transition duration-300 w-48 text-center"
              >
                Read Ktn Blog{" "}
              </NavLink>
            </div>
            <div className="absolute bottom-0 left-0 w-full flex flex-col items-center py-6">
              <p className="text-center text-white text-xs md:text-sm mb-4">
                We proudly associate with
              </p>
              <div className="flex justify-center space-x-3 md:space-x-20 mt-3">
                <a
                  href="https://wealthywisewoman.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-white rounded-full p-2"
                >
                  <img
                    src={Icon2}
                    alt="1"
                    className="h-8 w-8 md:h-11 md:w-11"
                  />
                </a>
                <a
                  href="https://chancellorcenterforlaw.ca/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-white rounded-full p-2"
                >
                  <img
                    src={Icon1}
                    alt="2"
                    className="h-8 w-8 md:h-11 md:w-11"
                  />
                </a>
                <a
                  href="https://largofinancialservices.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-white rounded-full p-2"
                >
                  <img
                    src={Icon3}
                    alt="3"
                    className="h-8 w-8 md:h-11 md:w-11"
                  />
                </a>
                <a
                  href="https://rjen.co.uk/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-white rounded-full p-2"
                >
                  <img
                    src={Icon4}
                    alt="4"
                    className="h-8 w-8 md:h-11 md:w-11"
                  />
                </a>
                <a
                  href="https://example.com/link5"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-white rounded-full p-2"
                >
                  <img
                    src={Icon5}
                    alt="5"
                    className="h-8 w-8 md:h-11 md:w-11"
                  />
                </a>
                <a
                  href="https://kamwe.tech/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-white rounded-full p-2"
                >
                  <img
                    src={Icon6}
                    alt="6"
                    className="h-8 w-8 md:h-11 md:w-11"
                  />
                </a>
                <a
                  href="https://humanlifeexpectancy.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-white rounded-full p-2"
                >
                  <img
                    src={Icon7}
                    alt="7"
                    className="h-8 w-8 md:h-11 md:w-11"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
