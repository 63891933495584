import React, { useEffect, useState } from "react";
import CopywritingImage from "../../../Images/pages/copyImage.webp";
import Donnie from "../../../Images/testimonialpics/donnie.webp";
import Megan from "../../../Images/testimonialpics/megan.webp";
import Solaru from "../../../Images/testimonialpics/solaru.webp";
import Path from "../../Home/Path";

const testimonials = [ 
  {
    name: "Donnie McFall, MBA",
    role: (
      <>
        Owner of McFall Consulting Inc.,
        <br />
        Minneapolis, Minnesota, United States.
      </>
    ),
    image: Donnie,
    rating: 5,
    header: "Awesome Team",
    testimonial:
      "This team is an awesome team. Truly awesome. That word is overused, but you truly fill that word with meaning. It is truly an honor to read your work.",
  },
  {
    name: "Megan Tribble",
    role: (
      <>
        Content Marketer and Coach,
        <br />
        Danville, Indiana, United States.
      </>
    ),
    image: Megan,
    rating: 5,
    header: "Creating Artwork With Words",
    testimonial:
      "Love the direction you went with the narrative, fantastic writing. Gosh, I can just imagine the types of people who would benefit from YOU telling their story, you’re creating artwork with words– fantastic. Keep up the good work.",
  },
  {
    name: "Elizabeth Solaru",
    role: (
      <>
        Director, Luxury Business Emporium,
        <br />
        London Area, United Kingdom.
      </>
    ),
    image: Solaru,
    rating: 5,
    header: "Contextualization and Personalization",
    testimonial:
      "Contextualization and personalization are what AI is lacking right now. There will always be a need for great writers like Kick’s.",
  },
];

const Copywriting = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);

  const next = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 3) % testimonials.length);
  };

  const prev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 3 + testimonials.length) % testimonials.length
    );
  };

  const getCurrentTestimonials = () => {
    return testimonials.slice(currentIndex, currentIndex + 3);
  };

  return (
    <>
      <div
        className="relative bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${CopywritingImage})` }}
      >
        <div className="relative bg-gray-900 bg-opacity-75 py-44 mt-10 md:py-60 md:mt-32">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className={`text-center ${loaded ? "slide-in" : ""}`}>
              <h1 className=" font-extrabold text-white text-3xl md:text-5xl">
                We use words to paint pictures so your audience pays attention
                and money{" "}
              </h1>
              <p className="mt-10 text-xl text-gray-300">
                Your part is living to create the content our job is telling
                your story by articulating and putting out content that sticks
                with your audience{" "}
              </p>
              <div className="mt-10 flex justify-center">
                <a
                  href="https://calendly.com/kalukalu/discovery-call"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block bg-[#14143a] hover:bg-indigo-600 text-white py-4 px-3 rounded-lg text-sm font-semibold mr-4 transition duration-300"
                >
                  Free Discovery Call
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      
        <div className="max-w-[1600px] mx-auto py-12">
      <h3 className="text-center text-blue-500 mb-4 text-md md:text-lg font-bold">
        A+ RATED GHOSTWRITING AND MARKETING SERVICES
      </h3>
      <h2 className="text-3xl font-bold text-center mb-12">
        Recent Testimonials
      </h2>
      <div className="flex justify-between items-center">
        <button
          onClick={prev}
          className="text-3xl p-2 text-gray-600 hover:text-gray-800"
        >
          {"<"}
        </button>
        <div className="flex justify-center space-x-4 overflow-hidden">
          {getCurrentTestimonials().map((item, index) => (
            <div
              key={index}
              className="bg-white border border-gray-300 shadow-md rounded-lg p-6 w-96 h-[700px] flex-shrink-0 flex flex-col justify-between"
            >
              <div className="flex-grow">
                <div className="flex justify-between items-center mb-4">
                  <div>
                    {[...Array(item.rating)].map((_, i) => (
                      <span key={i} className="text-yellow-500">
                        ★
                      </span>
                    ))}
                  </div>
                </div>
                <p className="text-lg font-bold">{item.header}</p>
                <p className="text-gray-600 mb-4 mt-6 whitespace-pre-line">
                  {item.testimonial}
                </p>
              </div>
              <div className="flex items-center">
                <img
                  src={item.image}
                  alt={item.name}
                  className="w-12 h-12 rounded-full mr-4"
                />
                <div>
                  <p className="font-bold">{item.name}</p>
                  <p className="text-sm">{item.role}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <button
          onClick={next}
          className="text-3xl p-2 text-gray-600 hover:text-gray-800"
        >
          {">"}
        </button>
      </div>
    </div>

      <div className="flex flex-col md:flex-row bg-blue-100 p-4 mb-10 md:p-10">
        <div className="flex-1 text-black p-6 md:p-0 md:text-sm md:pr-4">
          You can say “I’ve been a pilot for 20 years” or “I’ve flown commercial
          jets for over two decades” We compel sales for your business using the
          right words. The world rewards sensation before substance. Our words
          present your business as delivering something divine your customers
          can’t get elsewhere; they won’t just pay money, they’ll pay attention…
          you can multiply your business or remain where you are.
        </div>
        <a
          href="https://calendly.com/kalukalu/multiply-my-business?month=2023-09"
          target="_blank"
          rel="noopener noreferrer"
          className="mt-4 md:mt-0 md:ml-4 block bg-blue-500 hover:bg-red-600 text-white font-bold py-2 px-4 md:py-4 md:px-4 rounded text-center
                   sm:py-3 sm:px-6 sm:text-sm"
          // Adjusted padding and margins for better mobile and larger screen layout
        >
          MULTIPLY MY BUSINESS
        </a>
      </div>
      <Path />
    </>
  );
};

export default Copywriting;
