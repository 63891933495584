import React, { useState } from "react";
import Ralph from "../../Images/testimonialpics/Ralph.webp";
import Susan from "../../Images/testimonialpics/susan.webp";
import Christina from "../../Images/testimonialpics/chritiana.webp";
import Travis from "../../Images/testimonialpics/travis.webp";
import Ag from "../../Images/testimonialpics/ag.webp";
import Rebecca from "../../Images/testimonialpics/rebecca.webp";
import Donnie from "../../Images/testimonialpics/donnie.webp";
import Eze from "../../Images/testimonialpics/eze.webp";
import Megan from "../../Images/testimonialpics/megan.webp";
import David from "../../Images/testimonialpics/david.webp";
import Solaru from "../../Images/testimonialpics/solaru.webp";
import Tom from "../../Images/testimonialpics/Tom.jpg";

const testimonials = [
  {
    name: "Tom Nodine",
    role: (
      <>
        CEO and Founder at Human Life Expectancy Inc.,
        <br />
        Wilmette, Illinois, United States.
      </>
    ),
    image: Tom,
    rating: 5,
    header: "Multidisciplinary Approach",
    testimonial: `I've worked closely with Kalu and his team to help my insurtech start-up gain awareness and traction with clients. I'm extremely pleased with the results we have achieved. We've gone from "stealth" status, with virtually NO awareness and NO customers to now where I'm a sought-after keynote speaker at industry events. I lead a YouTube Channel with over 7,000 subscribers, I have some videos with over 50,000 views, and for the first time, clients are approaching me! 

    In addition, Kalu is smart, patient, fun to work with, and has proven himself to be a true "thought partner" who takes a multidisciplinary approach to achieve our business goals. I recommend him highly.`,
  },
  {
    name: "Susan Ritter",
    role: (
      <>
        Founder, Wealthy Wise Woman,
        <br />
        Greater Tampa Bay Area.
      </>
    ),
    image: Susan,
    rating: 5,
    header: "Great Storytellers",
    testimonial:
      "I believe, like being a composer, it is a gift to come up with a piece of writing that can move people to action- Only some people have this gift. I believe you are one of those people. For those who are not great at writing, Kick&co services are the answer to their problem. Oftentimes, telling our stories can be difficult. We want to put ourselves in the best light. We are a little too close to our own pain and pride to tell it in a way that is interesting to others. Having someone write it for us, great storytellers like the team here is a boon..",
  },
  {
    name: "Christina Beauchemin",
    role: (
      <>
        Author, Speaker, Educator, Let My Legacy Be Love, LLC,
        <br />
        Malden Bridge, New York, United States.
      </>
    ),
    image: Christina,
    rating: 5,
    header: "Wonderful Service",
    testimonial:
      "Ghostwriting is a skill. To catch the thoughts and voice of another person and put it into your words is a skill, not many people do well, but you are super talented, so I can see why you are successful. I think this is a wonderful service. So many people have great ideas and stories to tell, but they don't have the words. They don't understand the craft. In those many, many cases, this team can help them get a story told. I know I will recommend you! If creativity is not your thing and words are not your best asset, check out Kick’s services. They're great at what they do",
  },
  {
    name: "Travis Robbins",
    role: (
      <>
        Adventurer/Walker, The Connection Odyssey,
        <br />
        Watertown, South Dakota, United States.
      </>
    ),
    image: Travis,
    rating: 5,
    header: "Expertise and Openness",
    testimonial:
      "I want to send a big appreciation to Kick&co, I never thought I'd write a book until last year considering I didn't even like reading until last year. Your help, expertise, and openness to work with me is greatly appreciated",
  },
  {
    name: "Ag Goldsmith",
    role: (
      <>
        Finance Director, Hendrick Toyota Scion,
        <br />
        Charleston, South Carolina, United States.
      </>
    ),
    image: Ag,
    rating: 5,
    header: "Accountability Partners",
    testimonial:
      "This is a great team! Very intellectual, and efficient, and serves as great accountability partners for projects you work on.",
  },
  {
    name: "Rebecca Jenkins",
    role: (
      <>
        Founder at RJEN,
        <br />
        Bristol, United Kingdom.
      </>
    ),
    image: Rebecca,
    rating: 5,
    header: "Kick, You Walk The Talk",
    testimonial:
      "Kick, you walk the talk. Your range of services to help budding authors share their message with a wider audience is vital in a world of fake news, turmoil, and constant change. I always learn and am inspired when exploring the experiences of others who have faced challenges. It lifts both spirits and potential.",
  },
  {
    name: "Donnie McFall, MBA",
    role: (
      <>
        Owner of McFall Consulting Inc.,
        <br />
        Minneapolis, Minnesota, United States.
      </>
    ),
    image: Donnie,
    rating: 5,
    header: "Awesome Team",
    testimonial:
      "This team is an awesome team. Truly awesome. That word is overused, but you truly fill that word with meaning. It is truly an honor to read your work.",
  },
  {
    name: "Douglas Eze, CFF",
    role: (
      <>
        Founder & CEO, Largo Financial Services LLC,
        <br />
        Washington DC-Baltimore Area, United States.
      </>
    ),
    image: Eze,
    rating: 5,
    header: "Truly Remarkable",
    testimonial:
      "I love seeing a band of young people come together to create something and you have created something truly remarkable here; I happily recommend your services to people",
  },
  {
    name: "Megan Tribble",
    role: (
      <>
        Content Marketer and Coach,
        <br />
        Danville, Indiana, United States.
      </>
    ),
    image: Megan,
    rating: 5,
    header: "Creating Artwork With Words",
    testimonial:
      "Love the direction you went with the narrative, fantastic writing. Gosh, I can just imagine the types of people who would benefit from YOU telling their story, you’re creating artwork with words– fantastic. Keep up the good work.",
  },
  {
    name: "David D. Doerrier",
    role: (
      <>
        Presentation Empowerment Coach David D. Doerrier, LLC,
        <br />
        Atlanta Metropolitan Area, United States.
      </>
    ),
    image: David,
    rating: 5,
    header: "Adding Value and Creating Win-Win Partnerships",
    testimonial:
      "Your experience of ghostwriting is a great example of turning ideas into profitable ventures. It's all about adding value and creating win-win partnerships. Providing this valuable service has been your key to success. Well done!",
  },
  {
    name: "Elizabeth Solaru",
    role: (
      <>
        Director, Luxury Business Emporium,
        <br />
        London Area, United Kingdom.
      </>
    ),
    image: Solaru,
    rating: 5,
    header: "Contextualization and Personalization",
    testimonial:
      "Contextualization and personalization are what AI is lacking right now. There will always be a need for great writers like Kick’s.",
  },
  {
    name: "Ralph Danna P. A.",
    role: (
      <>
        Owner of Dannasales,
        <br />
        Spring Hill, Florida, United States.
      </>
    ),
    image: Ralph,
    rating: 5,
    header: "Unmatched Ghostwriting and Marketing Mastery",
    testimonial:
      "Kick&co creations are not mere narratives; they are exquisite tapestries of emotion and insight. Delve into their world for unmatched ghostwriting and marketing mastery. Beyond mere words, you'll be immersed in stories ignited by fervor and woven with depth. For those who demand extraordinary content and want to forge heartfelt connections with their audience, Kick&co is the pinnacle of artful expression.",
  },
];

const Testimonial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const next = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 3) % testimonials.length);
  };

  const prev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 3 + testimonials.length) % testimonials.length
    );
  };

  const getCurrentTestimonials = () => {
    return testimonials.slice(currentIndex, currentIndex + 3);
  };

  return (
    <div className="max-w-[1600px] mx-auto py-12">
      <h3 className="text-center text-blue-500 mb-4 text-md md:text-lg font-bold">
        A+ RATED GHOSTWRITING AND MARKETING SERVICES
      </h3>
      <h2 className="text-3xl font-bold text-center mb-12">
        Recent Testimonials
      </h2>
      <div className="flex justify-between items-center">
        <button
          onClick={prev}
          className="text-3xl p-2 text-gray-600 hover:text-gray-800"
        >
          {"<"}
        </button>
        <div className="flex justify-center space-x-4 overflow-hidden">
          {getCurrentTestimonials().map((item, index) => (
            <div
              key={index}
              className="bg-white border border-gray-300 shadow-md rounded-lg p-6 w-96 h-[700px] flex-shrink-0 flex flex-col justify-between"
            >
              <div className="flex-grow">
                <div className="flex justify-between items-center mb-4">
                  <div>
                    {[...Array(item.rating)].map((_, i) => (
                      <span key={i} className="text-yellow-500">
                        ★
                      </span>
                    ))}
                  </div>
                </div>
                <p className="text-lg font-bold">{item.header}</p>
                <p className="text-gray-600 mb-4 mt-6 whitespace-pre-line">
                  {item.testimonial}
                </p>
              </div>
              <div className="flex items-center">
                <img
                  src={item.image}
                  alt={item.name}
                  className="w-12 h-12 rounded-full mr-4"
                />
                <div>
                  <p className="font-bold">{item.name}</p>
                  <p className="text-sm">{item.role}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <button
          onClick={next}
          className="text-3xl p-2 text-gray-600 hover:text-gray-800"
        >
          {">"}
        </button>
      </div>
    </div>
  );
};

export default Testimonial;
