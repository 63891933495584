import WRIG from "../../Images/blogpics/afroprenueship/WRIG.webp";

const FeaturedPostConts = {
  title: "Why Reading Is Important for Ghostwriters",
  date: "October 11, 2024",
  description:
    "You have read somewhere that good writers are good readers. If you are considering getting into the ghostwriting profession, you must understand that success doesn’t come just from having a natural gift for words or a knack for storytelling. It’s a skill that requires continuous learning, improvement, and adaptability...",
  image: WRIG,
  author: "Afropreneurship",
};

export default FeaturedPostConts;
